import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d33a3ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-1" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-3 skillcol" }
const _hoisted_5 = { class: "sticky" }
const _hoisted_6 = { class: "filter" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "col-9"
}
const _hoisted_9 = { class: "sticky form" }
const _hoisted_10 = { class: "mt-3" }
const _hoisted_11 = { class: "units" }
const _hoisted_12 = {
  key: 0,
  class: "row mt-3"
}
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "row mt-3" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "row mt-3" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_TypeSelect = _resolveComponent("TypeSelect")!
  const _component_ModalButton = _resolveComponent("ModalButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addSkill && _ctx.addSkill(...args)))
        }, _toDisplayString(_ctx.$t('management.skills.new')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              placeholder: "Filter",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event))
            }, null, 512), [
              [_vModelText, _ctx.filter]
            ]),
            (_ctx.filter)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "icon",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter = ''))
                }, [
                  _createVNode(_component_Icon, {
                    path: _ctx.mdiClose,
                    width: 20,
                    height: 20
                  }, null, 8, ["path"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredskills, (skill) => {
          return (_openBlock(), _createElementBlock("div", {
            key: skill.skill.id,
            onClick: ($event: any) => (_ctx.selectSkill(skill.skill.id)),
            class: _normalizeClass(["skillitem", { active: skill.skill.id == _ctx.selected?.skill?.id }])
          }, _toDisplayString(skill.skill.name) + " ", 11, _hoisted_7))
        }), 128))
      ]),
      (_ctx.selected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_LabeledInput, {
                label: _ctx.$t('management.skills.name'),
                modelValue: _ctx.selected.skill.name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selected.skill.name) = $event)),
                onFocus: _ctx.onFocus
              }, null, 8, ["label", "modelValue", "onFocus"]),
              _createVNode(_component_LabeledSelect, {
                label: _ctx.$t('management.skills.type'),
                options: _ctx.groupoptions,
                modelValue: _ctx.selected.skill.group,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected.skill.group) = $event))
              }, null, 8, ["label", "options", "modelValue"]),
              _createVNode(_component_LabeledInput, {
                label: _ctx.$t('management.skills.desc'),
                modelValue: _ctx.selected.skill.description,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selected.skill.description) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('management.skills.addto')), 1),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected.units, (unit) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "unit",
                    key: unit.id
                  }, [
                    _createVNode(_component_Checkbox, {
                      label: unit.name,
                      modelValue: unit.selected,
                      "onUpdate:modelValue": ($event: any) => ((unit.selected) = $event)
                    }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                  ]))
                }), 128))
              ]),
              (_ctx.selectedcnt > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("h3", null, [
                        _createVNode(_component_Icon, {
                          path: _ctx.mdiStar,
                          width: 30,
                          height: 30
                        }, null, 8, ["path"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('legend.strategic')), 1)
                      ]),
                      (_openBlock(), _createBlock(_component_TypeSelect, {
                        units: _ctx.selected.units,
                        modelValue: _ctx.selected.skill.strategic,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selected.skill.strategic) = $event)),
                        key: _ctx.selected.skill.id
                      }, null, 8, ["units", "modelValue"]))
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("h3", null, [
                        _createVNode(_component_Icon, {
                          path: _ctx.mdiDiamond,
                          width: 30,
                          height: 30
                        }, null, 8, ["path"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('legend.future')), 1)
                      ]),
                      (_openBlock(), _createBlock(_component_TypeSelect, {
                        units: _ctx.selected.units,
                        modelValue: _ctx.selected.skill.future,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selected.skill.future) = $event)),
                        key: _ctx.selected.skill.id
                      }, null, 8, ["units", "modelValue"]))
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("h3", null, [
                    _createVNode(_component_Icon, {
                      path: _ctx.mdiCrown,
                      width: 30,
                      height: 30
                    }, null, 8, ["path"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('legend.leadership')), 1)
                  ]),
                  _createVNode(_component_Checkbox, {
                    modelValue: _ctx.selected.skill.leadership,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selected.skill.leadership) = $event)),
                    label: "Dieser Skill ist ein Leadership Skill"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("button", {
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.updateSkill && _ctx.updateSkill(...args)))
                  }, _toDisplayString(_ctx.$t('management.save')), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_ModalButton, {
                    class: "modalbutton",
                    link: `delete_skill_${_ctx.selected.skill.id}`
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", null, _toDisplayString(_ctx.$t('management.skills.delete')), 1)
                    ]),
                    _: 1
                  }, 8, ["link"]),
                  _createVNode(_component_Modal, {
                    link: `delete_skill_${_ctx.selected.skill.id}`,
                    title: _ctx.$t('management.deleteunit_title', { unit: _ctx.selected.skill.name })
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('management.skills.units')), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('management.skills.grades')), 1),
                      _createElementVNode("p", null, [
                        _createElementVNode("button", {
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.removeSkill(_ctx.selected?.skill.id)))
                        }, _toDisplayString(_ctx.$t('management.deleteconfirm')), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["link", "title"])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}